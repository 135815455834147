<template>
  <IchibanTemplate>
    <template v-slot:body>
      <div class="liked-wrapper">
        <h2 class="text-center white--text">我的最愛一番賞抽獎紀錄</h2>
        <div
          v-for="ichiban in likedIchibans"
          class="ichiban-card"
          @click="
            $router.push({
              name: 'IchibanPage',
              params: { id: ichiban._id },
            })
          "
        >
          <div class="image-box">
            <v-img
              aspect-ratio="1.5"
              width="100%"
              :src="ichiban.previewImageUrl"
              cover
            ></v-img>
            <v-icon
              @click.stop="handleUnlike(ichiban._id)"
              color="pink"
              class="image-box__btn"
            >
              mdi-heart
            </v-icon>
          </div>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">號碼</th>
                  <th class="text-left">獎項</th>
                  <th class="text-left">得獎者</th>
                  <th class="text-left">抽出時間</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in ichiban.rewards" :key="item.number">
                  <td class="text-left">
                    {{ item.number }}
                  </td>
                  <td class="text-left">
                    {{ item.reward }}
                  </td>
                  <template v-if="item.hit">
                    <td class="text-left">
                      {{ maskName(findRecord(item, ichiban._id)?.userName) }}
                    </td>
                    <td class="text-left">
                      {{ findRecord(item, ichiban._id)?.createdAt }}
                    </td>
                  </template>
                  <template v-else>
                    <td class="text-left">---</td>
                    <td class="text-left">---</td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </template>
  </IchibanTemplate>
</template>

<script>
import { mapState } from "vuex";
import IchibanTemplate from "./IchibanTemplate.vue";

export default {
  name: "",
  props: [],
  components: { IchibanTemplate },
  data: () => ({
    likedIchibans: null,
    records: [],
  }),
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
    }),
    winRecords() {
      return this.records.filter((record) => !!record?.rewards.reward);
    },
    likedIchibanIds() {
      if (this.likedIchibans) {
        return this.likedIchibans.map((ichiban) => ichiban._id);
      }
      return [];
    },
  },
  methods: {
    async load() {
      await Promise.all([this.getLikedIchibans(), this.getIchibanRecords()]);
    },
    async getLikedIchibans() {
      const { data } = await this.axios.get(`/users/favorites/${this.userID}`);
      this.likedIchibans = data.ichibans.filter(
        (ichiban) => ichiban.isAvailable && ichiban.isOnline
      );
    },
    async getIchibanRecords() {
      const { data } = await this.axios.get(`/ichibanRecord`);
      this.records = data.reverse();
    },
    async handleUnlike(id) {
      this.$swal
        .fire({
          title: "從我的最愛移除?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "確認",
          cancelButtonText: "取消",
          reverseButtons: true,
          // customClass: {
          //   confirmButton: "btn btn-success",
          //   cancelButton: "btn btn-danger",
          // },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // 直接更新 likedIchibans 讓畫面響應更新樣式
            let payload = this.likedIchibanIds;
            if (payload.includes(id)) {
              const index = payload.indexOf(id);
              payload.splice(index, 1);
              await this.axios.post(`/users/favorites`, {
                userID: this.userID,
                ichibans: payload,
              });
              await this.load();
              this.$forceUpdate();
            }
          }
        });
    },
    getIsSoldout(ichiban) {
      return (
        ichiban.count === ichiban.sold ||
        ichiban.rewards.every((reward) => !!reward.hit)
      );
    },
    getWinRecord(id) {
      return this.winRecords.filter((record) => record.ichibanId === id);
    },
    findRecord(reward, ichibanId) {
      return this.getWinRecord(ichibanId).find(
        (record) =>
          record.rewards.number === reward.number &&
          record.rewards.reward === reward.reward
      );
    },
    maskName(str) {
      if (!str || str.length === 0) return "";
      if (str.length === 1) {
        return str;
      } else if (str.length === 2) {
        return str.charAt(0) + "*";
      } else {
        let firstChar = str.charAt(0);
        let lastChar = str.charAt(str.length - 1);
        let maskedMiddle = "*".repeat(str.length - 2);
        return firstChar + maskedMiddle + lastChar;
      }
    },
  },
  async created() {
    this.$vloading.show();
    await this.load();
    this.$vloading.hide();
  },
};
</script>

<style scoped lang="scss">
.liked-wrapper {
  max-width: 400px;
  margin: 0 auto;
}

.ichiban-card {
  margin-top: 20px;
  background: #000;
  color: white;
  cursor: pointer;
}

.image-box {
  position: relative;
  &__btn {
    position: absolute;
    top: 15px;
    right: 15px;

    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(0.9);
    }
  }
}
</style>
