import { render, staticRenderFns } from "./IchibanLiked.vue?vue&type=template&id=7b3450bb&scoped=true&"
import script from "./IchibanLiked.vue?vue&type=script&lang=js&"
export * from "./IchibanLiked.vue?vue&type=script&lang=js&"
import style0 from "./IchibanLiked.vue?vue&type=style&index=0&id=7b3450bb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b3450bb",
  null
  
)

export default component.exports